import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import smartschoolLogo from '../../../assets/img/smartschool.png';
import bingleLogo from '../../../assets/img/bingle.png';
import classroomLogo from '../../../assets/img/classroom.png';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';

const Dashboard = () => {
  return (
    <>
      <Row className="g-4">
        {[
          {
            variant: 'light',
            image: smartschoolLogo,
            height: '85%',
            width: '85%',
            link: 'https://olvp.smartschool.be'
          },
          {
            variant: 'light',
            image: bingleLogo,
            height: '65%',
            width: '55%',
            link: 'https://olvp.smartschool.be'
          },
          {
            variant: 'light',
            image: classroomLogo,
            height: '100%',
            width: '45%',
            link: 'https://olvp.smartschool.be'
          },
          {
            name: 'Inhaaltoetsen',
            variant: 'success',
            description: 'Planning van de inhaaltoetsen in Google Drive.',
            link: 'https://olvp.smartschool.be'
          }
        ].map((item, idx) => (
          <Col sm={6} lg={3} key={item.variant}>
            <Link
              to={{
                pathname: item.link
              }}
              target="_blank"
            >
              <Card
                bg={item.variant.toLowerCase()}
                key={idx}
                text={item.variant.toLowerCase() === 'light' ? 'dark' : 'white'}
              >
                <Card.Body
                  as={Flex}
                  style={{
                    height: '140px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {item.image && (
                    <Card.Img
                      style={{ height: item.height, width: item.width }}
                      src={item.image}
                    />
                  )}
                  <div
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    {item.name && <Card.Title as="div">{item.name}</Card.Title>}
                    {item.description && (
                      <Card.Text>{item.description}</Card.Text>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
