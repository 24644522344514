export const homeRoute = {
  label: 'Home',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      icon: 'chart-pie',
      to: '/'
    }
  ]
};
export const drukkerijRoutes = {
  label: 'Drukkerij',
  children: [
    {
      name: 'Mijn Drukwerk',
      icon: 'calendar-alt',
      to: '/print/myprints',
      active: true
    },
    {
      name: 'Wachtrij',
      icon: 'comments',
      role: 'DRUKKERIJ',
      active: true,
      children: [
        {
          name: 'Gewoon Drukwerk',
          icon: 'calendar-alt',
          to: '/print/queue/gewoon_drukwerk',
          active: true
        },
        {
          name: 'Examen Drukwerk',
          icon: 'comments',
          active: true,
          to: '/print/queue/examen_drukwerk'
        }
      ]
    },
    {
      name: 'Facturatie',
      icon: 'calendar-day',
      active: true,
      role: 'BOEKHOUDING',
      to: '/print/invoicing'
    }
  ]
};
export const nascholingenRoutes = {
  label: 'Nascholingen',
  children: [
    {
      name: 'Mijn Aanvragen',
      icon: 'calendar-alt',
      to: '/app/calendar',
      active: true
    },
    {
      name: 'Beheer',
      icon: 'comments',
      to: '/app/chat'
    }
  ]
};

export const communicatieRoutes = {
  label: 'Communicatie',
  children: [
    {
      name: 'Kalender',
      icon: 'calendar-alt',
      to: '/app/calendar',
      active: true
    },
    {
      name: 'Veelgestelde vragen',
      icon: 'comments',
      to: '/app/chat'
    }
  ]
};

export const beheerRoutes = {
  label: 'Beheer',
  role: 'BEHEERDER',
  children: [
    {
      name: 'Schoolbestuur',
      icon: 'calendar-alt',
      to: '/beheer/schoolboard',
      active: true,
      role: 'BEHEERDER'
    },
    {
      name: 'Klassen',
      icon: 'calendar-alt',
      to: '/beheer/studentgroups',
      active: true,
      role: 'BEHEERDER'
    },
    {
      name: 'Leerlingen',
      icon: 'comments',
      to: '/beheer/students',
      role: 'BEHEERDER'
    },
    {
      name: 'Medewerkers',
      icon: 'comments',
      to: '/beheer/employees',
      role: 'BEHEERDER'
    },
    {
      name: 'Lokalen',
      icon: 'comments',
      to: '/beheer/rooms',
      role: 'BEHEERDER'
    },
    {
      name: 'Job Beheer',
      icon: 'comments',
      to: '/beheer/jobs',
      role: 'BEHEERDER'
    },
    {
      name: 'Properties',
      icon: 'comments',
      to: '/beheer/property',
      role: 'BEHEERDER'
    }
  ]
};

export default [
  homeRoute,
  drukkerijRoutes,
  nascholingenRoutes,
  communicatieRoutes,
  beheerRoutes
];
