import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/jobs';

class JobService {
  async syncTimeTables() {
    const response = await axios.post(API_URL + '/timetables', undefined, {
      headers: {
        ...authHeader()
      }
    });

    return response.data;
  }
}

export default new JobService();
