import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/property';

class PropertyService {
  async getProperty(propertyName) {
    const response = await axios.get(API_URL + '/' + propertyName, {
      headers: authHeader()
    });
    return await response.data;
  }

  async getProperties() {
    const response = await axios.get(API_URL + '/all', {
      headers: authHeader()
    });
    return await response.data;
  }

  async registerOrUpdateProperty(data) {
    const response = await axios.put(API_URL, data, {
      headers: {
        ...authHeader()
      }
    });
    return await response.data;
  }
}

export default new PropertyService();
