import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { ProgressBar } from 'react-bootstrap';
import FileService from 'services/file.service';
import IconAlert from 'components/common/IconAlert';
import JobService from 'services/job.service';

const JobManager = () => {
  const [uploadingFile, setUploadingFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState(undefined);

  const onDrop = useCallback(acceptedFiles => {
    console.log('test');
    setUploadingFile(acceptedFiles[0]);
    console.log(acceptedFiles[0]);

    FileService.upload(acceptedFiles[0], 'uurrooster.txt', event => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(response => {
        setMessage(response.data.message);
        setUploadingFile(undefined);
        setProgress(0);
      })
      .catch(() => {
        setProgress(0);
        setUploadingFile(undefined);
        setMessage('Could not upload the file!');
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 2,
    onDrop: onDrop
  });

  const syncTimeTables = () => {
    JobService.syncTimeTables().then(
      data => {
        setMessage(data.message);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  return (
    <>
      {message && (
        <IconAlert
          variant="success"
          dismissible
          onClose={() => setMessage(undefined)}
        >
          <p className="mb-0">{message}</p>
        </IconAlert>
      )}
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Uurrooster
            </h5>
            <IconButton
              variant="outline-primary"
              className="mb-1"
              icon="sync-alt"
              transform="shrink-3"
              onClick={() => syncTimeTables()}
            >
              Sync Now
            </IconButton>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop your files here</p>
            </Flex>
          </div>

          {uploadingFile && <ProgressBar animated={true} now={progress} />}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default JobManager;
