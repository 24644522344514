import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/auth/';

class AuthService {
  async login(school, userName, pass) {
    const response = await axios.post(API_URL + 'login', null, {
      params: { school, userName, pass }
    });

    return await response.data;
  }

  logout() {
    localStorage.removeItem('user');
  }

  logoutAndRedirect() {
    const history = useHistory();
    localStorage.removeItem('user');
    history.push('/');
    window.location.reload();
  }

  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      try {
        var decodedToken = jwt.verify(
          user.jwt,
          'vr4DPhlSCsghWObnw9OMHvODqenSrnW-ArsA70P8v8o'
        );

        if (!decodedToken || Date.now() >= decodedToken.exp * 1000) {
          this.logoutAndRedirect();
        }
      } catch (err) {
        this.logoutAndRedirect();
      }
    }

    return user;
  }

  hasRole(role) {
    const user = this.getCurrentUser();

    if (user == null) {
      return false;
    }

    return (
      user.roles.indexOf(role.toUpperCase()) > -1 ||
      user.roles.includes('BEHEERDER')
    );
  }
}

export default new AuthService();
