import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/files';

class FileService {
  upload(file, name, onUploadProgress) {
    console.log(file);

    let formData = new FormData();
    formData.append('file', file, name);

    console.log(formData);

    return axios.post(API_URL + '/upload', formData, {
      headers: {
        ...authHeader()
      },
      onUploadProgress
    });
  }
}

export default new FileService();
