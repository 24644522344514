import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';

import PropertyService from 'services/property.service';
import Select from 'react-select';

const Properties = () => {
  const booleanWaarde = [
    { value: 0, label: 'false' },
    { value: 1, label: 'true' },
    { value: 2, label: 'none' }
  ];

  const [properties, setProperties] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [property, setProperty] = useState({});
  const [boolWaarde, setBoolWaarde] = useState(2);

  useEffect(() => {
    PropertyService.getProperties().then(data => {
      setProperties(data);
    });
  }, []);

  const onSubmitSearch = event => {
    event.preventDefault();
  };

  const onChangeName = e => {
    setProperty({
      ...property,
      name: e.target.value
    });
  };

  const onChangeString = e => {
    setProperty({
      ...property,
      stringValue: e.target.value
    });
  };

  const onChangeInteger = e => {
    setProperty({
      ...property,
      integerValue: e.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    PropertyService.registerOrUpdateProperty({
      ...property,
      booleanValue: boolWaarde == 2 ? undefined : boolWaarde
    }).then(
      () => {
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Property
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="align-items-center g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={property.name}
                  type="text"
                  required
                  onChange={onChangeName}
                />
                <Form.Control.Feedback type="invalid">
                  Gelieve een naam op te geven
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="instNr">
                <Form.Label>String Waarde</Form.Label>
                <Form.Control
                  value={property.instNr}
                  type="text"
                  required
                  onChange={onChangeString}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="instNr">
                <Form.Label>Integer Waarde</Form.Label>
                <Form.Control
                  value={property.instNr}
                  type="text"
                  required
                  onChange={onChangeInteger}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="instNr">
                <Form.Label>Boolean Waarde</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={booleanWaarde}
                  placeholder="Select..."
                  classNamePrefix="react-select"
                  value={booleanWaarde[boolWaarde]}
                  onChange={value => setBoolWaarde(value.value)}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="submit">
                <Button type="submit" color="primary" className="mt-4 me-3">
                  Opslaan
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
      <FalconComponentCard>
        <FalconComponentCard.Header className="border-bottom" noPreview>
          <Flex justifyContent="start" alignItems="center">
            <h5 className="mb-0 hover-actions-trigger" id="geplandDrukwerk">
              Properties
            </h5>
          </Flex>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Form className="mb-4" onSubmit={onSubmitSearch}>
            <Row className="align-items-center g-3">
              <Form.Group as={Col} controlId="name">
                <Form.Control
                  type="text"
                  placeholder="Zoek een property..."
                  name="search"
                  value={searchQuery}
                  onInput={e => setSearchQuery(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="submit">
                <Button type="submit" color="primary" className="me-3">
                  Zoeken
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <Table responsive>
            <thead>
              <tr>
                <th scope="col">Naam</th>
                <th scope="col">String Waarde</th>
                <th scope="col">Integer Waarde</th>
                <th scope="col">Boolean Waarde</th>
                <th className="text-end" scope="col">
                  Acties
                </th>
              </tr>
            </thead>
            <tbody>
              {properties && (
                <>
                  {properties
                    .filter(
                      property =>
                        !searchQuery ||
                        property.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(property => (
                      <tr key={property.name}>
                        <td>{property.name}</td>
                        <td>
                          {property.stringValue == undefined
                            ? 'N/A'
                            : property.stringValue}
                        </td>
                        <td>
                          {property.integerValue == undefined
                            ? 'N/A'
                            : property.integerValue}
                        </td>
                        <td>
                          {property.booleanValue == undefined
                            ? 'N/A'
                            : property.booleanValue
                            ? 'true'
                            : 'false'}
                        </td>
                        <td className="text-end">
                          <ActionButton
                            icon="edit"
                            title="Bewerken"
                            variant="action"
                            className="p-0 me-2"
                          />
                          <ActionButton
                            icon="trash-alt"
                            title="Verwijderen"
                            variant="action"
                            className="p-0"
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default Properties;
